import React from 'react';

export default function OverviewCard(props) {

    const {selected, reset, location, availableLocations} = props;

    return (
        <div className="card overview">
                <div className="card-header">
                  <button className="btn reset-btn btn-sm float-end" onClick={() => reset()}>Reset</button>
                  <p className='mb-0'>Locatie: {location !== '' ? availableLocations.find(item => item.nr === location)?.location : ''}</p>
                  Uw abonnement: <span style={{color:'#fff', fontWeight:500}}>{selected.obj !== undefined && Object.keys(selected.obj).length !== 0 ? selected.obj.Description : 'Selecteer abonnement'}</span> 
                </div>
                {selected.mode ?
                <>
                {/* <div className="card-body">
                  <div className="row">

                    
                    <div className="col-6">
                      <p>Abonnementskosten</p>
                    </div>
                    <div className="col-6 text-end">
                      <p>{selected.obj !== undefined && Object.keys(selected.obj).length !== 0 ? '€ '+selected.obj.Amount : 'Selecteer abonnement'} <br/> 
                      <small>{selected.obj !== undefined && Object.keys(selected.obj).length !== 0 ? (
                        <p>Per {selected.obj.PaymentDuration} {selected.obj.PaymentDurationPeriod}</p>                                                 
                      ) : 'Selecteer abonnement'} {selected.obj !== undefined && Object.keys(selected.obj).length !== 0 && selected.obj.onlyOnce ? (
                            'Eenmalig'                                     
                      ) : ''}</small></p>
                    </div>
                    
                  </div>
                </div> */}
                {/* <div className="card-body">
                  <div className="row">

                    <div className="col-12">
                      <p><strong>Eenmalige kosten</strong></p>
                    </div>
                    <div className="col-6">
                      <p>Inschrijfgeld</p>
                    </div>
                    <div className="col-6 text-end">
                      <p>0000</p>
                    </div>

                    <div className="col-6">
                      <p>Administratiekosten</p>
                    </div>
                    <div className="col-6 text-end">
                      <p>0000</p>
                    </div>

                    <div className="col-6">
                      <p>Borg</p>
                    </div>
                    <div className="col-6 text-end">
                      <p>0000</p>
                    </div>
                    
                  </div>
                </div> */}
                {/* <div className="card-body">
                  <div className="row">
                  <div className="col-12">
                      <p><strong>Actie</strong></p>
                    </div>
                    <div className="col-6">
                      <p>Join the best club in town - 4 wkn gratis</p>
                    </div>
                    <div className="col-6 text-end">
                      <p>0000</p>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">                  
                    <div className="col-6">
                      <p>Kosten abonnement eerste periode</p>
                    </div>
                    <div className="col-6 text-end">
                      <p>0000</p>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">                  
                    <div className="col-6">
                      <p><strong>Totaal eerste periode</strong></p>
                    </div>
                    <div className="col-6 text-end">
                      <p>0000</p>
                    </div>
                  </div>
                </div> */}
                </>
                :
                <div className="card-body text-center">
                    <p>Selecteer abonnement</p>
                </div>
}
              </div>
    )
}