import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import SubscribeFields from './Components/SubscribeFields';
import numeral from 'numeral';
import OverviewCard from './Components/OverviewCard';
import moment from 'moment';


const base_api_url = 'https://fitness365.nl'


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      memberships: [],
      firstCostsMembership: {},
      enableSubscribe: {mode:false, id:0,obj:{}},
      loading: true,
      subscribing: false,
      filterMembershipsBy: 'Abonnementen',
      memberShipGroups: ['Always F!T', 'Premium F!T', 'Always F!T' ],
      memberShipPlan: null,
      // startDate: null,
      base64Sig: '',
      location: '',
      availableLocations: [
        {location: 'Alphen a/d Rijn', nr: 0},
        {location: 'Amsterdam', nr: 1},
        {location: 'Hooglanderveen', nr: 2},
      ],
    };

    this.selectLocation = this.selectLocation.bind(this);

  }

  


  componentDidMount(){
    this.setState({loading: false})
  }

  checkAddress(){
    //Url to go
    //https://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:7161DM&fq=huisnummer=45

  }

  getCurrentNiceDate(){
    var currentDate = new Date();

var day = currentDate.getDate();
var month = currentDate.getMonth() + 1;
var year = currentDate.getFullYear();

day = (day < 10) ? '0' + day : day;
month = (month < 10) ? '0' + month : month;

var formattedDate = day + '/' + month + '/' + year;
return formattedDate;
  }

  startSubscribing(obj){
    
    if(this.state.enableSubscribe.id === obj.MembershipId){
      this.setState(
        {
          enableSubscribe: {mode:false,id:0}
        }
      )
    } else {
      //Get first costs for selected membership
      let self = this;

    axios.post(base_api_url+'/wp-json/subscription/firstcost?location='+this.state.location, {
      data:{
        mid: obj.MembershipId,
        StartDate: this.getCurrentNiceDate(),   
      },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json', // Make sure to set the Content-Type header
        'Origin': 'https://inschrijven.fitness365.nl', // Add the Origin header for CORS pre-flight request
      },
    } )
    .then(function (response) {
    
      self.setState(
        {          
          firstCostsMembership: response.data
        }
      )         
    })
    .catch(function (error) {
      //console.log(error);
    });
      this.setState(
        {          
          enableSubscribe: {mode:true,id:obj.MembershipId,obj:obj}
        }
      )
    }
    
  }

  connectCustomerWithMembership(subscriber,user){
    let self = this;
    axios.post(base_api_url+'/wp-json/subscription/subscribe/connect', {
      data:{
        CustomerId: user,
        MembershipDefinitionID: subscriber,
        StartDate: "01/01/2020",
        SendEmail: true,
        Base64Signature: self.state.base64Sig
      }
    })
    .then(function (response) {
      //console.log(response);      
      if(response.data.MembershipActive === "true"){
        self.setState({
          subscribing: false
        })
        // toast.success('Succesvol ingeschreven!')
      }
    })
    .catch(function (error) {
      //console.log(error);
    });
  }

  connectTempCustomer(memberShipId, customerId,TransactionId,Link){
    let data = {
      memberId: memberShipId,
      customer: customerId,
      transaction: TransactionId,
      signature: this.state.base64Sig,
      location: this.state.location
    }
    axios.post(base_api_url+'/wp-json/subscription/temp-customers', {
      data:data
    })
    .then(function (response) { 
      //console.log(response.data);  
      window.location.href = Link;
    })
    .catch(function (error) {
      //console.log(error);
    });
  }

  generatePaymentLink(customerId){
    console.log('payment link', this.state.firstCostsMembership)
    const testData = {
      CustomerId: customerId,
      Amount: this.state.firstCostsMembership.MembershipFirstCostsWithDeposits,
      ReturnURL: "https://fitness365.nl/bedankt-voor-je-aanmelding-lid-worden/",
      ReturnURLCancel: "https://fitness365.nl/helaas-is-er-wat-fout-gegaan",
      ReturnURLError: "https://fitness365.nl/helaas-is-er-wat-fout-gegaan",
      ReturnURLReject: "https://fitness365.nl/helaas-is-er-wat-fout-gegaan",
      Description: this.state.firstCostsMembership.MembershipDescription
    }

    var self = this;


    axios.post(base_api_url+'/wp-json/subscription/subscribe/payment?location='+this.state.location, {
      data:testData
    })
    .then(function (response) { 
      //console.log(response.data);
      //Handle errors
      if(response.hasOwnProperty('data') && response.data.hasOwnProperty('Link')){
        self.connectTempCustomer(self.state.enableSubscribe.id,customerId,response.data.TransactionId, response.data.Link);
    
      } else {
        //Do nothing
      }

     
    })
    .catch(function (error) {
      //console.log(error);
    });

  }
 
  createCustomer(e){
    e.preventDefault()


    if(this.state.base64Sig == ''){
      alert('Gelieve uw handtekening te vullen');
      return false;
    }


    const form = e.target;
const data = Object.fromEntries(new FormData(form).entries());
//console.log('register customer', data);

// Validate data
let validations = [];
for (const [key, value] of new FormData(form).entries()) {
  const field = form.elements[key];
  if (field.required && value === '') {
    validations.push(key);
  }
  if (key === 'BirthDate') {
    data.BirthDate = moment(value).format('DD/MM/YYYY');
  }
}

if (validations.length > 0) {
  alert('Fill all required fields: ' + validations.join(","));
  return false;
}
    
    
    this.setState({
      subscribing: true
    })
    // axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    var self = this;
    axios.post(base_api_url+'/wp-json/subscription/subscribe?location='+this.state.location, {
      data:data
    })
    .then(function (response) { 
      //console.log(response.data);
      //Handle errors
      if(response.data.message !== undefined){
        toast.error(response.data.message);
        self.setState({
          subscribing: false
        })
        return false;
      }
      if(response.data.CustomerId !== ''){
        //console.log(self.state.enableSubscribe.id,response.data.CustomerId)
       
        self.generatePaymentLink(response.data.CustomerId)        
      }
    })
    .catch(function (error) {
      //console.log(error);
    });
  }

  getLocationImage = (location) =>  {
    const locationLower = location.toLowerCase();
    let imagePath = '';
    
    if (locationLower === 'amsterdam') {
      imagePath = process.env.PUBLIC_URL + '/img/amsterdam.jpg';
    } else if (locationLower === 'alphen a/d rijn') {
      imagePath = process.env.PUBLIC_URL + '/img/alphen.jpg';
    } else if (locationLower === 'hooglanderveen') {
      imagePath = process.env.PUBLIC_URL + '/img/hooglanderveen.jpeg';
    }
  
    if (imagePath) {
      return <img src={imagePath} alt={'Fitness365 ' + location} />;
    }
  
    // If no match, return null (or any other fallback component you want to display)
    return null;
  }
  
  selectLocation = (obj) => {
    this.setState(
      {
        location: obj.nr,
        memberships: [],
        loading: true
      },
      () => {
        const url = `${base_api_url}/wp-json/subscription/info?location=${this.state.location}`;
        try {
          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              //console.log(data);
              if (data.data) {
                this.setState({
                  memberships: data.data.MembershipDefinitions,
                  loading: false
                });
               // console.log(this.state.memberships);
              }
            });
        } catch (e) {
          console.log(e);
          this.setState({
            loading: false
          });
        }
      }
    );
  };
 

  render(){
    return (
      <>
      <div className="container">
        
        <div className="row">
          <div className="col-md-12 p-3">
          <img src="https://fitness365.nl/wp-content/themes/fitness365/assets/img/logo-new.png" width="400px" alt="" className="main-logo img-fluid"/>
          </div>
          <div className={this.state.memberships.length == 0 ? 'col-md-12' : 'd-none'}>
              <h3>Selecteer locatie</h3>
              <div className='row'>
                { this.state.availableLocations.map((a,k) => {
                  return (
                    <div className="col-md-4">
                        <div className={'card package location my-2' + (this.state.location === a.nr ? ' selected' : '')} onClick={() => this.selectLocation(a)}>
                            {this.getLocationImage(a.location)}
                            <h4>{a.location}</h4>
                        </div>
                    </div>
                  ) 
                })

                }
              </div>
          </div>
          <div className={this.state.enableSubscribe.mode ? 'col-md-12' : 'col-md-12'}>
          <div className={this.state.memberships.length == 0 || this.state.enableSubscribe.mode  ? 'd-none' : 'select-cards'}>
          <button className='btn reset-btn btn-sm float-end' onClick={() => this.setState({memberships: [], enableSubscribe:{mode:false, id:0,obj:{}}})}>Vorige</button>

          <h3>Selecteer abonnement</h3>
          <p className='mb-0 text-primary fw-bold'>Locatie: {this.state.location !== '' ? this.state.availableLocations.find(item => item.nr === this.state.location)?.location : ''}</p>

       
          <div className='row'>
                <div className="col-6 my-2">
                  <button className={'filter-btn' + (this.state.filterMembershipsBy === 'Abonnementen' ? ' active' : '')} onClick={() => this.setState({filterMembershipsBy: 'Abonnementen'})}>Abonnementen</button>
                </div>
                <div className="col-6 my-2">
                  <button className={'filter-btn' + (this.state.filterMembershipsBy === 'Upgrades' ? ' active' : '')} onClick={() => this.setState({filterMembershipsBy: 'Upgrades'})}>Upgrades</button>
                </div>                
              </div>
 

              {
    this.state.filterMembershipsBy === 'Abonnementen' &&
    (() => {
        const locationName = this.state.location !== '' ? this.state.availableLocations.find(item => item.nr === this.state.location)?.location : '';
        let plans = ['Always F!T', 'Premium F!T', 'Special F!T'];

        let columnClass = "col-4";
        if (locationName === 'Alphen a/d Rijn') {
            plans = plans.filter(plan => plan !== 'Premium F!T');
            columnClass = "col-6";
        }

        return (
            <div className='row'>
                {plans.map(plan => (
                    <div key={plan} className={`${columnClass} my-2`}>
                        <button 
                            className={'filter-btn' + (this.state.memberShipPlan === plan ? ' active' : '')}
                            onClick={() => this.setState({memberShipPlan: plan})}
                        >
                            {plan}
                        </button>
                    </div>
                ))}
            </div>
        );
    })()
}
          
          
          
          <div className="row">
          { this.state.memberships && this.state.memberShipPlan !== null && this.state.memberships.length > 0 &&
              this.state.memberships.filter( obj => {
                if(this.state.filterMembershipsBy === 'Abonnementen'){
                  console.log(obj.Description.toLowerCase(), this.state.memberShipPlan.toLowerCase(), obj.Description.toLowerCase().includes(this.state.memberShipPlan.toLowerCase()))
                  return obj.Description.toLowerCase().includes(this.state.memberShipPlan.toLowerCase());
                }else {
                  return obj;
                }
              }).sort((a, b) => {
                const groupA = a.Description.toLowerCase();
                const groupB = b.Description.toLowerCase();
                return groupA.localeCompare(groupB); // Use localeCompare for case-insensitive sorting
              }).map(obj => {
                if (obj.Active && obj.MembershipGroup === this.state.filterMembershipsBy) {
                  return (
                    <div className="col-md-4">
                  <div className={'card package my-2' + (this.state.enableSubscribe.id === obj.MembershipId ? ' selected' : '')} onClick={() => this.startSubscribing(obj)}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                    {/* <p className="mb-0"><strong>{obj.LocationName}</strong></p> */}
                    <p className='mb-0 text-primary'>{obj.MembershipGroup}</p>    
                    <p className='fs-6 mb-0'>{obj.Description}</p>     
                    <p className='small'>{obj.ContractDescription}</p>
              
                    </div>
                    <div className="col-md-12 text-end">
                      <p className="fs-4 fw-bold m-0">€ {numeral(obj.Amount).format('0.00')}</p>
                      <p className='mb-0 small'>Per {obj.PaymentDuration} {obj.PaymentDurationPeriod}</p> 
                      { obj.onlyOnce &&
                        <p className='mb-0 small'>Eenmalig</p>
                      }
                    </div>
                  </div>
                  
                </div>
                </div>
                </div>
                )
                } else {
                  return false;
                }
              
              })            
          }
          </div>
          </div>
          <div className={this.state.enableSubscribe.mode ? 'col-md-12' : 'd-none'}>          
              <OverviewCard selected={this.state.enableSubscribe} location={this.state.location} availableLocations={this.state.availableLocations} reset={() => this.setState({
                enableSubscribe: {mode:false, id:0,obj:{}},
                memberships: [],
                location: ''
              })}/>          
          </div>
          { this.state.enableSubscribe.mode &&
            <form onSubmit={(e) => this.createCustomer(e)}>  
              {/* <h3>Vul uw gegevens in</h3> */}
                <SubscribeFields saveSig={(sig) => this.setState({base64Sig:sig})} firstCostsMembership={this.state.firstCostsMembership} selected={this.state.enableSubscribe} />
            </form>
          }
       
          </div>
          
        </div>
        
      </div>
     { this.state.loading &&
        <div className={this.state.loading ? 'loading-screen d-flex' : 'loading-screen d-flex hide'}>
          <div className="align-self-center w-100 text-center">
          <img src="https://fitness365.nl/wp-content/themes/fitness365/assets/img/logo-new.png" width="400px" alt="" className="loader-logo img-fluid mb-4"/>
            <div className="w-100">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
     }
     { this.state.subscribing &&
        <div className={this.state.subscribing ? 'loading-screen d-flex' : 'loading-screen d-flex hide'}>
          <div className="align-self-center w-100 text-center">
          <img src="https://fitness365.nl/wp-content/themes/fitness365/assets/img/logo-new.png" width="400px" alt="" className="img-fluid mb-4"/>
            <div className="w-100">
              <p>Inschrijving verwerken</p>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
     }
           <Toaster />
      </>
    );
  }
}

export default App;
