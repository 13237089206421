import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'

// import moment from 'moment';


export default function SubscribeFields(props) {

   const {saveSig, firstCostsMembership, selected} = props;

    const [birthDate,setBirtData] = useState(0);
    const [base64Sig, setBase64Sig] = useState('');
    const [step, setStep] = useState(1)
    
    const sigPad = useRef(null);

    const handleSaveSignature = () => {
        const canvas = sigPad.current.getCanvas();
        const dataURL = canvas.toDataURL();
        setBase64Sig(dataURL);
        saveSig(dataURL);
    };

    const clearSignature = () => {
        sigPad.current.clear()
        setBase64Sig('');
        saveSig('');
    }

    const nextStep = () => {
        if (step < 4) {
            const currentStepNode = document.querySelector('.row.active');
            const requiredFields = Array.from(currentStepNode.querySelectorAll('input[required], select[required]'));
            const allFieldsFilled = requiredFields.every(field => field.value);
    
            if (!allFieldsFilled) {
                alert("Gelieve alle verplichten velden te vullen.");
                return;
            }
    
            let a = step + 1;
            setStep(a)
        }
    }

    const prevStep = () => {
        if(step > 1){
            let a = step - 1;
            setStep(a)
        }
    }

    const goToStep = (nr) => {
        for (let i = 1; i < nr; i++) {
            const stepNode = document.querySelector(`.step-${i}`);
            const requiredFields = Array.from(stepNode.querySelectorAll('input[required], select[required]'));
            const allFieldsFilled = requiredFields.every(field => field.value);
    
            if (!allFieldsFilled) {
                alert(`Gelieve eerst alle verplichte velden te vullen in stap ${i}.`);
                return;
            }
        }
        setStep(nr)
    }

    const getTotalFirstCosts = () => {
        const sum = firstCostsMembership.AmountDeposit1 + firstCostsMembership.AmountDeposit2 + firstCostsMembership.AmountDeposit3;
        const formattedSum = sum.toLocaleString('nl-NL', {
            style: 'currency',
            currency: 'EUR'
          });
        
        return formattedSum;

    }

    const moneyFormat = (m) => {
        if(m === undefined){
            
        } else {
            const formattedSum = m.toLocaleString('nl-NL', {
                style: 'currency',
                currency: 'EUR'
              });
            
            return formattedSum;
        }
    }

    const requiredField = () => {
        return <span style={{color:'red'}}>*</span>
    }

    return (
        <div className="subscribe-form">
            <div className='subscribe-form-nav'>
                <div onClick={() => goToStep(1)} className={'step-nav-item ' + (step == 1 ? 'active' : '')}>
                    <p className='number'>1</p>
                    <p className='type'>Persoonsgegevens</p>
                </div>
                <div onClick={() => goToStep(2)} className={'step-nav-item ' + (step == 2 ? 'active' : '')}>
                <p className='number'>2</p>
                    <p className='type'>Adres</p>
                </div>
                <div onClick={() => goToStep(3)} className={'step-nav-item ' + (step == 3 ? 'active' : '')}>
                <p className='number'>3</p>
                    <p className='type'>Overig</p>
                </div>
                <div onClick={() => goToStep(4)} className={'step-nav-item ' + (step == 4 ? 'active' : '')}>
                <p className='number'>4</p>
                    <p className='type'>Afronden</p>
                </div>
            </div>
        <div className={"row step-1 " + (step == 1 ? 'active' : 'hidden')}>
            <div className="col-md-12">
                <h5>Persoonsgegevens</h5>
            </div>
            <div className="col-md-4">
                <label>Titel {requiredField()}</label>
                <select name="FirstName" className="form-control" required>        
                    <option value=""></option>
                    <option value="Dhr">Dhr.</option>
                    <option value="Mevr">Mevr.</option>    
                </select>
            </div>
            <div className="col-md-4">
                <label>Voornaam {requiredField()}</label>
                <input name="FirstName" className="form-control" required/>                
            </div>
            <div className="col-md-4">
                <label>Achternaam {requiredField()}</label>
                <input name="LastName" className="form-control" required/>                
            </div>
            <div className="col-md-6">
                <label>Initialen {requiredField()}</label>
                <input name="Initials" className="form-control" required/>                
            </div>
            <div className="col-md-6">
                <label>Tussenv.</label>
                <input name="MiddleName" className="form-control"/>                
            </div>
            </div>
            <div className={"row step-2 " + (step == 2 ? 'active' : 'hidden')}>

            <div className="col-md-12">
                <h5>Adres</h5>
            </div>
            <div className="col-md-4">
                <label>Postcode {requiredField()}</label>
                <input name="Zipcode" className="form-control" autocomplete="off" required/>                
            </div>
            <div className="col-md-4">
                <label>Huisnummer {requiredField()}</label>
                <input name="HouseNumber" className="form-control" autocomplete="off" required/>                
            </div>
            <div className="col-md-4">
                <label>Toevoeging</label>
                <input name="HouseNumberExtension" className="form-control" autocomplete="off"/>                
            </div>
            <div className="col-md-4">
                <label>Straat {requiredField()}</label>
                <input name="Address" className="form-control" autocomplete="off" required/>                
            </div>
            <div className="col-md-4">
                <label>Woonplaats {requiredField()}</label>
                <input name="City" className="form-control" autocomplete="off" required/>                
            </div>
            <div className="col-md-4">
                <label>Land {requiredField()}</label>
                <select name="Country" className="form-control" required>                
                <option value=""></option>
                <option value="Nederland">Nederland</option>
                <option value="South_Africa">Zuid-Afrika</option>
                <option value="Belgie">België</option>
                <option value="Deutschland">Duitsland</option>
                <option value="France">Frankrijk</option>
                <option value="Swiss">Zwitserland</option>
                <option value="Singapore">Singapore</option>
                <option value="Hongkong">Hong-kong</option>
                <option value="Austria">Oostenrijk</option>
                <option value="Sweden">Zweden</option>
                <option value="USA">VS</option>
                <option value="Japan">Japan</option>
                <option value="Kenia">Kenia</option>
                <option value="Noorwegen">Noorwegen</option>
                <option value="Portugal">Portugal</option>
                <option value="UK">UK</option>
                <option value="Curacao">Curacao</option>
                </select>
            </div>
        </div>
        <div className={"row step-3 " + (step == 3 ? 'active' : 'hidden')}>

            <div className="col-md-12">
                <h5>Overig</h5>
            </div>
            <div className="col-md-12">
                <label>Geboortedatum {requiredField()}</label>
                <input type="date" name="BirthDate" className="form-control" required/>                
            </div>
            <div className="col-md-4">
                <label>Telefoonnummer</label>
                <input name="PhonePrivate" className="form-control"/>                
            </div>
            <div className="col-md-4">
                <label>Mobiel nummer {requiredField()}</label>
                <input name="PhoneMobile" className="form-control" required/>                
            </div>
            <div className="col-md-4">
                <label>E-mailadres {requiredField()}</label>
                <input name="Email" className="form-control" required/>                
            </div>
            <div className="col-md-12">
                <label>Communicatietaal {requiredField()}</label>
                <select name="Language" className="form-control" required>                
                <option value=""></option>
                <option value="nl_NL">Nederlands</option>
                <option value="en_GB">Engels</option>
                </select>
            </div>
            </div>
            <div className={"row step-4 " + (step == 4 ? 'active' : 'hidden')}>

            <div className="col-md-12">
                <h5>Financieel</h5>
            </div>
            <div className='col-md-12 my-3 pricing-table'>
                <h6>Geselecteerde abonnement:</h6>
                <table className="table text-white">
                    <tr>
                        <td>{firstCostsMembership.MembershipDescription}</td>
                        <td className='text-end'>
                            <p className='m-0 p-0'>{moneyFormat(firstCostsMembership.MembershipFirstCosts)}</p>
                            <p className='mb-0 p-0 small'>Per {selected.obj.PaymentDuration} {selected.obj.PaymentDurationPeriod}</p> 
                            </td>
                    </tr>
                </table>
                <h6>Eenmalige kosten:</h6>
                <table className='table text-white'>
                    <tr>
                        <td>{firstCostsMembership.Deposit1}</td>
                        <td className='text-end'>{moneyFormat(firstCostsMembership.AmountDeposit1)}</td>
                    </tr>
                    <tr>
                        <td>{firstCostsMembership.Deposit2}</td>
                        <td className='text-end'>{moneyFormat(firstCostsMembership.AmountDeposit2)}</td>
                    </tr>
                    <tr>
                        <td>{firstCostsMembership.Deposit3}</td>
                        <td className='text-end'>{moneyFormat(firstCostsMembership.AmountDeposit3)}</td>
                    </tr>
                    <tr className='last-row'>
                        <td><strong>Totaal eerste periode:</strong></td>
                        <td className='text-end'><strong>{moneyFormat(firstCostsMembership.MembershipFirstCostsWithDeposits)}</strong></td>
                    </tr>
                </table>
            </div>
            <div className="col-md-6">
                <label>BIC {requiredField()}</label>
                <select name="BIC" className="form-control" required>
                    <option value="" disabled selected>Selecteer jouw bank</option>
                    <option value="ABNANL2A">ABN AMRO Bank</option>
                    <option value="INGBNL2A">ING Bank</option>
                    <option value="RABONL2U">Rabobank</option>
                    <option value="SNSBNL2A">SNS Bank</option>
                    <option value="VOWANL21">De Volksbank</option>
                    <option value="TRIONL2U">Triodos Bank</option>
                    <option value="ASNBNL21">ASN Bank</option>
                    <option value="RBRBNL21">Regiobank</option>
                    <option value="KNABNL2H">KNAB</option>
                    <option value="FVLBNL22">Van Lanschot</option>
                    <option value="BNGHNL2G">BNG Bank</option>
                    <option value="NIBCNL2G">NIBC Bank</option>
                    <option value="DEUTNL2N">Deutsche Bank Nederland</option>
                </select>                
            </div>
            <div className="col-md-6">
                <label>IBAN {requiredField()}</label>
                <input name="IBAN" className="form-control" required/>                
            </div>            
            <div className="col-md-12">
                <label>Rekeninghouder {requiredField()}</label>
                <input name="account" className="form-control" required/>                
            </div>
            <div className='col-md-12'>
            <label className='my-2 d-block'><input type="checkbox" name="acceptance" required/> Ik ga akkoord met de <a href="https://fitness365.nl/wp-content/uploads/2023/08/000-F365-2023-07-Lidmaatschapsregelement-en-voorwaarden-Fitness365-versie-2023.7.pdf" target="_blank" style={{color:'#2ac4f4'}}>algemene voorwaarden</a> {requiredField()}</label>
            <label className='my-2 d-block'><input type="checkbox" name="sepa_acceptance" required/> Ik ga akkoord met doorlopende <strong style={{color:'#2ac4f4'}}>Sepa machtiging</strong> {requiredField()}</label>
            <label className='my-2 d-block'><input type="checkbox" name="avg_acceptace" required/> Ik ga akkoord met de <a href="https://fitness365.nl/wp-content/uploads/2023/08/000-365-2023-07-AVG-sportivity.pdf" target="_blank" style={{color:'#2ac4f4'}}>AVG voorwaarden</a> {requiredField()}</label>
            <label className='d-block mb-1'>Handtekening{requiredField()}</label>
            <SignatureCanvas penColor='blue' ref={sigPad} onEnd={handleSaveSignature} backgroundColor="#eeeeee" canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
            <button type="button" className="clean-sig" onClick={() => clearSignature()}>Handtekening wissen</button>
            <input type="hidden" name="base64SignatureCode" value={base64Sig} />
            </div>

            <div className="col-md-12 mt-3">
                <button type="submit" className="btn btn-primary w-100">Aanmelden</button>
            </div>
        </div>
        <div className="step-navigation">
            { step !== 1 &&
                <button type="button" className='prev' onClick={() => prevStep()}>Vorige</button>
            }
            { step !== 4 &&
                <button type="button" className={'next '} onClick={() => nextStep()} >Volgende</button>
            }
        </div>
        </div>
    )
}